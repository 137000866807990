import React from 'react'
import { Link, graphql } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import Img from 'gatsby-image'
import Space from '@rebass/space'
import Button from '../components/Button'
import Card from '../components/Card'
import Container from '../components/Container'
import Accordion from '../components/Accordion'
import SEO from '../components/SEO'
import styled, { ThemeConsumer } from 'styled-components'

// Icons
import IconEmail from '../images/vector/icon-email.svg'

const StyledCodeSnippet = styled(Box)`
  background-color: #f5f5f5;
  font-family: 'SF Mono', SFMono-Regular, ui-monospace, 'DejaVu Sans Mono',
    Menlo, Consolas, monospace;
  font-size: 0.85rem;
  padding: 16px;
  border-radius: 7px;
`

const Support = ({ data }) => {
  return (
    <ThemeConsumer>
      {theme => (
        <>
          <SEO title="Updates • What's new in IconJar" />

          {/* Hero */}
          <Container my={[4, 5]}>
            <Box textAlign={'center'}>
              <h1>Support</h1>
            </Box>
          </Container>

          {/* General support */}
          <Container my={[4, 5]} width={[1, 3 / 5]}>
            <Space mb={3}>
              <h4>General support</h4>
            </Space>
            <Card>
              <Accordion question="Download IconJar v1 (legacy)">
                <p>
                  The latest update for IconJar v1 can be downloaded from here:
                </p>
                <Button
                  as="a"
                  href="https://dl.devmate.com/com.iconjar.iconjar/Iconjar.zip"
                  colorscheme="white">
                  Download IconJar v1
                </Button>
              </Accordion>
              <Accordion question="Using SVGs with apps that don't support them">
                <p>
                  Some apps like Keynote and Photoshop don't play nice with SVGs
                  right off the bat. When you try to drop an SVG from Iconjar
                  into these apps, you might see an error pop up. No need to
                  worry, though! You can use our Quickdrag feature, which lets
                  you drag and drop icons in any file format you want. Most apps
                  that don't like SVGs work pretty well with PDF files, so we
                  recommend going for that option. PDFs are scalable and come
                  with almost the same perks as SVGs.
                </p>
                <Img
                  fluid={data.QuickDrag.childImageSharp.fluid}
                  alt="Quickdrag"
                  style={{ borderRadius: '6px' }}
                />
              </Accordion>
              <Accordion question="Educational discounts">
                <p>
                  We offer a 20% discount on IconJar for the first year.
                  Discounts for .edu email addresses are automatically
                  calculated and applied during checkout.
                </p>
                <p>
                  If you have a different email address but require a discount
                  for educational purposes you can send us an email with a copy
                  of your student ID.
                </p>
                <Button
                  as="a"
                  href="mailto:info@geticonjar.com"
                  colorscheme="white">
                  <IconEmail
                    style={{ marginRight: '8px', marginTop: '-2px' }}
                  />
                  Email us
                </Button>
              </Accordion>
              <Accordion question="Changing the scope of the search input">
                <p>
                  Sometimes you want the search form to search through your
                  complete icon library. However, there are plenty of usecases I
                  which you want the search form to respect the scope you've
                  selected in the left sidebar. Changing the scope of the search
                  form is extremely easy and is found when you select the input
                  field and click on the little search icon that transitions to
                  the left.
                </p>
                <Img
                  fluid={data.SearchScope.childImageSharp.fluid}
                  alt="Quickdrag"
                  style={{ borderRadius: '6px' }}
                />
              </Accordion>
              <Accordion
                question="Use your library on multiple Macs"
                description="Why you shouldn't use cloud services, and what to use instead."
                style={{ borderBottom: 'none' }}>
                <p>
                  IconJar doesn't support the ability to use your library on
                  multiple Macs natively. We also don't recommend using a
                  service like iCloud, Dropbox, or Google Drive to upload your
                  library to the cloud because they may break your library due
                  to sync conflicts*. However, there's a viable workaround!
                </p>
                <p>
                  <i>
                    * The library will only break when the IconJar library is
                    synced from both machines simultaneously.{' '}
                  </i>
                </p>
                <hr />
                <h5>Using Git as a workaround</h5>
                <p>
                  The best way to use the same library on multiple Macs is Git,
                  for example through <a href="https://github.com/">GitHub</a>{' '}
                  or <a href="https://bitbucket.org/product/">Bitbucket</a>. It
                  allows you to push updates manually, and get your library in
                  sync on other Macs by pulling the changes from Git. As a
                  bonus; it gives you versioning for free!
                </p>
                <p>
                  We recommend using apps like{' '}
                  <a href="https://www.git-tower.com/mac">Tower</a> (paid) or{' '}
                  <a href="https://www.sourcetreeapp.com/">Sourcetree</a> (free)
                  since they make it significantly more straightforward (and
                  less scary) to use Git. Here's how to{' '}
                  <a href="https://www.git-tower.com/help/guides/branches-and-tags/pull/mac">
                    pull
                  </a>{' '}
                  /{' '}
                  <a href="https://www.git-tower.com/help/guides/branches-and-tags/push/mac">
                    push
                  </a>{' '}
                  with Tower, and this is how it works in{' '}
                  <a href="https://confluence.atlassian.com/sourcetreekb/commit-push-and-pull-a-repository-on-sourcetree-785616067.html">
                    Sourcetree
                  </a>
                  .
                </p>
                <h6>Add support for Git to your existing library:</h6>
                <p>
                  <i>
                    Note: To complete these steps you'll need a remote
                    repository.
                  </i>
                </p>
                <p>
                  <strong>Step 1: Locate the library on your Mac</strong>
                  <br />
                  Open IconJar's preferences → Library → double click the
                  Library breadcrumb. This should open a Finder tab of where
                  your library is stored.
                </p>
                <p>
                  <strong>
                    Step 2: Navigate to the library with your Mac's
                  </strong>
                  <br />
                  Type cd and drag and drop your library onto the Terminal
                  window. You should see something like this after you hit
                  return:
                </p>
                <Img
                  fluid={data.TerminalSyncScreenshot.childImageSharp.fluid}
                  alt="Quickdrag"
                  style={{ borderRadius: '6px' }}
                />
                <p>
                  <strong>
                    Step 3: Initialise Git and make your first commit
                  </strong>
                  <br />
                  Paste the following in your terminal:
                  <StyledCodeSnippet mt={3}>
                    git init <br />
                    git add . <br />
                    git commit -m "Convert to Git library"
                  </StyledCodeSnippet>
                </p>
                <p>
                  <strong>Step 4: Configure the remote repository</strong>
                  <br />
                  Paste the following in your terminal and make sure to check
                  the user- and repository names:
                  <StyledCodeSnippet mt={3}>
                    git remote add origin
                    {'https://github.com/{yourusername}/ij-library.git'}
                  </StyledCodeSnippet>
                </p>
                <p>
                  <strong>Step 5: Push your library to the repository</strong>
                  <br />
                  Paste the following in your terminal and you're done. Make
                  sure to check the branch your GitHub repository is using. In
                  some cases your branch will be called main, but in others it's
                  master.
                  <StyledCodeSnippet mt={3}>
                    git push -u origin main
                  </StyledCodeSnippet>
                </p>
              </Accordion>
            </Card>
          </Container>

          {/* Subscription and/or license related issues */}
          <Container my={[4, 5]} width={[1, 3 / 5]}>
            <Space mb={3}>
              <h4>Manage your subscription or license</h4>
            </Space>
            <Card>
              <Accordion question="Reset a license">
                <p>
                  We understand that you might encounter activation limits for
                  IconJar, which are capped at two per license.
                </p>
                <h6>For IconJar 2 licenses:</h6>
                <p>
                  Whether you have a lifetime license or an annual subscription,
                  you can contact us and provide the following details:
                </p>
                <ul>
                  <li>
                    The email address you used when purchasing your license.
                  </li>
                  <li>The license key</li>
                </ul>
                <p>
                  Please note that subscription licenses are reset by our
                  vendor, while lifetime licenses are reset by our team.
                  Resetting subscription licenses through our vendor may take a
                  bit more time, and we apologize in advance for any
                  inconvenience this may cause.
                </p>
                <Button
                  as="a"
                  href="mailto:info@geticonjar.com"
                  colorscheme="white">
                  <IconEmail
                    style={{ marginRight: '8px', marginTop: '-2px' }}
                  />
                  Email us
                </Button>
                <hr />
                <h6>For IconJar 1 licenses:</h6>
                <p>
                  You can reset your license for IconJar v1 on{' '}
                  <Link to="/reset-license">this webpage</Link>.
                </p>
              </Accordion>
              <Accordion question="Cancel or change subscription">
                <p>
                  Feel free to cancel your IconJar subscription for any reason.
                  You can cancel it by reaching out to us or simply replying to
                  the order confirmation email you received during the sign-up
                  process.
                </p>
                <p>
                  Your access to IconJar will remain active until the
                  subscription's expiration date after cancellation.
                </p>
                <Button
                  as="a"
                  href="mailto:info@geticonjar.com"
                  colorscheme="white">
                  <IconEmail
                    style={{ marginRight: '8px', marginTop: '-2px' }}
                  />
                  Email us
                </Button>
              </Accordion>
              <Accordion
                question="Recover a lost license key"
                style={{ borderBottom: 'none' }}>
                <p>
                  You might find yourself switching to a new Mac or accidentally
                  deleting your IconJar order confirmation email while tidying
                  up your inbox. It's a common occurrence, and there's no need
                  to fret. Just drop us an email and we'll gladly assist you in
                  retrieving your license.
                </p>
                <Button
                  as="a"
                  href="mailto:info@geticonjar.com"
                  colorscheme="white">
                  <IconEmail
                    style={{ marginRight: '8px', marginTop: '-2px' }}
                  />
                  Email us
                </Button>
              </Accordion>
            </Card>
          </Container>

          {/* Troubleshooting */}
          <Container my={[4, 5]} width={[1, 3 / 5]}>
            <Space mb={3}>
              <h4>Troubleshooting</h4>
            </Space>
            <Card>
              <Accordion question="IconJar crashes on launch">
                <p>
                  The first thing we recommend doing is to{' '}
                  <Link to="/Link">download the latest version of IconJar</Link>
                  . It is possible that IconJar can't launch due to an issue
                  with a specific version of macOS which means it will never
                  trigger the update dialogue.
                </p>
                <p>
                  If that doesn't work and you still get a crash, it might be
                  due to malformed/conflicting preferences. Open up Terminal and
                  type the following command:{' '}
                  <code>defaults delete com.iconjar.iconjar</code>.
                </p>
                <p>
                  Then hit return, this will remove any preferences you have set
                  and revert back to IconJar's defaults. Then you can launch
                  IconJar again.
                </p>
                <p>
                  A third, and last possibility, would be that your library got
                  broken. To be sure, backup the following direction;
                  <code>~/library/Application Support/Iconjar</code>. Delete the
                  directory and start the app again. You'll notice that your
                  icons are gone, therefore we always recommend people to keep a
                  backup of their icons somewhere.
                </p>
                <p>
                  If neither of these work, please reach out to us for
                  additional support.
                </p>
                <Button
                  as="a"
                  href="mailto:info@geticonjar.com"
                  colorscheme="white">
                  <IconEmail
                    style={{ marginRight: '8px', marginTop: '-2px' }}
                  />
                  Email us
                </Button>
              </Accordion>
            </Card>
          </Container>

          {/* Developer docs */}
          <Container my={[4, 5]} width={[1, 3 / 5]}>
            <Space mb={3}>
              <h4>Developer docs</h4>
            </Space>
            <Card>
              <Accordion question="Generate .IconJar files with PHP">
                <p>
                  We often receive questions from people who want to generate
                  .IconJar files on their website. As per request, we wrote a
                  PHP library that does just that and you can find it on GitHub.
                  The <code>index.php</code> contains an example that should get
                  you up and running in no-time.
                </p>
                <p>
                  Since this library is open-source we highly appreciate it if
                  you contribute by creating pull requests or issues.
                </p>
                <Button
                  as="a"
                  href="https://github.com/IconJar/iconjar-exporters"
                  colorscheme="white">
                  Check it out on GitHub
                </Button>
              </Accordion>
            </Card>
          </Container>
        </>
      )}
    </ThemeConsumer>
  )
}

export default Support

export const pageQuery = graphql`
  query {
    QuickDrag: file(relativePath: { eq: "quickdrag.png" }) {
      childImageSharp {
        fluid(maxWidth: 726, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    SearchScope: file(relativePath: { eq: "search-scope.png" }) {
      childImageSharp {
        fluid(maxWidth: 726, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    TerminalSyncScreenshot: file(
      relativePath: { eq: "terminal-sync-screenshot.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 726, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
