import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled, { ThemeConsumer } from 'styled-components'
import { Flex, Box } from 'reflexbox'
import { useState } from 'react'
import IconChevron from '../images/vector/icon-chevron.svg'

const StyledAccordion = styled(Box)`
  border-bottom: 1px solid #f5f5f5;
`

const StyledAccordionHeader = styled(Box)`
  cursor: pointer;
  position: relative;
  h6 {
    display: inline-block;
    margin: 0;
  }

  svg {
    position: absolute;
    right: 32px;
    top: calc(50% - 8px);
    height: 16px;
  }

  &.expanded svg {
    transform: rotate(90deg);
  }
`
const StyledAccordionBody = styled(Box)`
  display: none;
  visibility: hidden;

  &.expanded {
    display: inherit;
    visibility: inherit;
  }
`

const Accordion = props => {
  const [accordionState, setAccordionState] = useState('collapsed')

  function switchAccordionState() {
    if (accordionState == 'collapsed') {
      setAccordionState('expanded')
    } else {
      setAccordionState('collapsed')
    }
  }

  return (
    <StyledAccordion {...props}>
      <StyledAccordionHeader
        p="4"
        onClick={switchAccordionState}
        className={accordionState}>
        <h6>{props.question}</h6>
        <p className="faded" style={{ marginBottom: '0' }}>
          {props.description}
        </p>
        <IconChevron />
      </StyledAccordionHeader>
      <StyledAccordionBody p="4" pt={0} className={accordionState}>
        {props.children}
      </StyledAccordionBody>
    </StyledAccordion>
  )
}

export default Accordion
